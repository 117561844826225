@use "../config/" as *;
.waf-squadlisting {
    @extend %p-4;
    @extend %pure-white-900-bg;
    @extend %my-6;
    .head-wrap {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: .1rem solid clr(neutral-1000);
        gap: var(--space-4);
        @extend %mb-4;
        @extend %pb-4;
    }
    .title,
    .sub-title {
        @extend %font-16-pb;
        @extend %secondary-800;
        @extend %uppercase;
    }
    .team {
        &-group:not(:first-child) {
            border-top: .1rem solid clr(neutral-1000);
            @extend %mt-5;
            @extend %pt-5;
        }
        &-title {
            @extend %mb-7;
            @extend %font-16-pb;
            @extend %secondary-800;
        }
        &-listing {
            @extend %flex-column;
            @extend %gap-4;
        }
        &-item {
            border: .1rem solid clr(neutral-1000, 3);
            @extend %flex-n-c;
            @extend %p-4;
            @extend %relative;
        }
        &-image {
            height: 3rem;
            width: 5rem;
        }
        &-name {
            @extend %font-16-pb;
            @extend %ml-3;
            @extend %neutral-800;
        }
        &-action {
            @extend %ml-auto;
            &:after {
                @include icon(p-arrow-right);
                @extend %neutral-800;
            }
            .btn-more {
                @include position(0, null, null, 0, absolute);
                @extend %h-100;
                @extend %w-100;
            }
            .btn-text {
                @extend %font-0;
            }
        }
    }
    .btn-disabled {
        opacity: .5;
        pointer-events: none;
    }
}
@include mq(col-md) {
    .waf-squadlisting {
        .head-wrap {
            margin-bottom: var(--space-5);
            padding-bottom: var(--space-5);
        }
        .team {
            margin-bottom: var(--space-6);
            padding-bottom: var(--space-6);
        }
    }
}